<template>
  <b-card
    text-variant="center"
    @click="onClick(to)"
    :class="`cursor-pointer bg-${color} card-dashboard`"
  >
    <b-avatar :variant="color" size="60" class="box-shadow-4 mb-1">
      <feather-icon size="28" :icon="icon" />
    </b-avatar>
    <h5 class="mb-0 mt-50 text-white">{{ title }}</h5>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    to: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClick: function (param) {
      this.$router.push(param);
    },
  },
};
</script>
