<template>
  <div>
    <b-row>
      <b-col>
        <h5 class="font-medium-3 mb-2">
          Hi, <b>{{ userData.name }}</b>
        </h5>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <!-- <b-col cols="6" lg="3" v-if="permissions.includes('policy-view')">
        <card-dashboard
          icon="FileTextIcon"
          color="primary"
          title="Polis Saya"
          to="/policies"
        />
      </b-col>
      <b-col cols="6" lg="3" v-if="permissions.includes('claim-view')">
        <card-dashboard
          icon="FileTextIcon"
          color="primary"
          title="Klaim Saya"
          to="/claims"
        />
      </b-col> -->
      <!-- <b-col cols="6" lg="3" v-if="permissions.includes('pemrek-view')">
        <card-dashboard
          icon="FileTextIcon"
          color="primary"
          title="Pemrek Saya"
          to="/pemrek-verify"
        />
      </b-col> -->
      <!--
      <b-col lg="3" sm="12">
        <card-dashboard
          icon="FileTextIcon"
          color="warning"
          title="Bukti Pemrek Danamon"
          to="/pemrek-verify"
        />
      </b-col>
      <b-col lg="3" sm="12">
        <card-dashboard
          icon="FileTextIcon"
          color="primary"
          title="Bukti Pemrek Aladin"
          to="/pemrek-aladin-verify"
        />
      </b-col>
      <b-col lg="3" sm="12">
        <card-dashboard
          icon="FileTextIcon"
          color="warning"
          title="Pinjaman Adira"
          to="/adira/loan"
        />
      </b-col> -->
      <b-col lg="3" sm="12">
          <card-dashboard
            icon="FileTextIcon"
            color="primary"
            title="Bukti Pemrek Mandiri"
            to="/pemrek-mandiri-verify"
          />
        </b-col>

      <b-col lg="3" sm="12">
            <card-dashboard
              icon="FileTextIcon"
              color="danger"
              title="Bukti Pemrek Octo Pay"
              to="/pemrek-octo-verify"
            />
          </b-col>
      <!-- <b-col lg="3" sm="12">
        <card-dashboard
          icon="ShoppingBagIcon"
          color="primary"
          title="Merchant QR"
          to="/merchant"
        />
      </b-col> -->
    </b-row>
    <!-- <b-row
      class="justify-content-center"
      v-if="permissions.includes('policy-view')"
    > -->
    <!-- <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="FileTextIcon"
          color="primary"
          statistic="0"
          statistic-title="Polis minggu ini"
        />
      </b-col> -->
    <!-- <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="FileTextIcon"
          color="primary"
          statistic="0"
          statistic-title="Polis hari ini"
        />
      </b-col> -->
    <!-- </b-row> -->
    <!-- <b-row
      class="justify-content-center"
      v-if="permissions.includes('pemrek-view')"
    >
      <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="FileTextIcon"
          color="primary"
          statistic="0"
          statistic-title="Pemrek minggu ini"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="FileTextIcon"
          color="primary"
          statistic="0"
          statistic-title="Pemrek hari ini"
        />
      </b-col>
    </b-row> -->
    <!-- <b-row
      class="justify-content-center"
      v-if="
        permissions.includes('policy-create') ||
        permissions.includes('policy-view') ||
        permissions.includes('claim-view') ||
        permissions.includes('claim-create')
      "
    >
      <b-col lg="6" sm="6">
        <b-card>
          <b-card-body>
            <p>Statistik Bulan ini</p>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row> -->
    <vue-fab
      mainBtnColor="#3867d6"
      size="big"
      fabItemAnimate="alive"
      :scrollAutoHide="false"
      :globalOptions="fabOption"
    >
      <fab-item
        v-for="(item, idx) in menu"
        :idx="idx"
        :title="item.title"
        :color="item.color"
        icon="assignment"
        @clickItem="clickItem(item.type)"
        :key="idx"
      />

      <!-- <fab-item
        :idx="0"
        title="Polis"
        color="#45aaf2"
        icon="assignment"
        @clickItem="clickItem(0)"
        v-if="permissions.includes('policy-create')"
        class="add-polis"
      />

      <fab-item
        :idx="1"
        title="Klaim"
        color="#45aaf2"
        icon="assignment"
        @clickItem="clickItem(1)"
        v-if="permissions.includes('claim-create')"
        class="add-claim"
      />

      <fab-item
        :idx="0"
        title="Pemrek"
        color="#FFCC00"
        icon="assignment"
        @clickItem="clickItem(2)"
        v-if="permissions.includes('pemrek-create')"
        class="add-pemrek"
      /> -->
    </vue-fab>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BAvatar } from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import CardDashboard from "@/layouts/components/CardDashboard";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    StatisticCardHorizontal,
    CardDashboard,
  },
  data() {
    return {
      menu: [],
      permissions: null,
      userData: null,
      fabOption: {
        spacing: 60,
      },
    };
  },
  created() {
    var $permissions = JSON.parse(localStorage.getItem("userPermission"));
    this.permissions = $permissions;

    let $userData = JSON.parse(localStorage.getItem("userData"));
    this.userData = $userData;
    /*
    this.menu.push({
      title: "Pinjaman Adira",
      color: "#ff9f43",
      type: 3,
    });

    this.menu.push({
      title: "Bukti Pemrek Aladin",
      color: "#45aaf2",
      type: 2,
    });

    this.menu.push({
      title: "Bukti Pemrek Danamon",
      color: "#ff9f43",
      type: 1,
    }); */

    this.menu.push({
      title: "Bukti Pemrek Mandiri",
      color: "#45aaf2",
      type: 4,
    });

    this.menu.push({
      title: "Bukti Pemrek Octo Pay",
      color: "#ec5454",
      type: 6,
    });

    

    // for (let i = 0; i < $permissions.length; i++) {
    //   if ($permissions[i] == "policy-create") {
    //     this.menu.push({
    //       title: "Polis",
    //       color: "#45aaf2",
    //       type: 0,
    //     });
    //   }

    //   if ($permissions[i] == "claim-create") {
    //     this.menu.push({
    //       title: "Klaim",
    //       color: "#45aaf2",
    //       type: 1,
    //     });
    //   }

    //   if ($permissions[i] == "pemrek-create") {
    //     this.menu.push({
    //       title: "Bukti Pemrek",
    //       color: "#45aaf2",
    //       type: 2,
    //     });
    //   }

    //   if ($permissions[i] == "merchant-create") {
    //     this.menu.push({
    //       title: "Merchant QR",
    //       color: "#45aaf2",
    //       type: 3,
    //     });
    //   }
    // }
  },
  methods: {
    clickItem: function (item) {
      switch (item) {
        // case 0:
        //   this.$router.push("/policy/add");
        //   break;
        // case 1:
        //   this.$router.push("/claim/add");
        //   break;
        // case 2:
        //   this.$router.push("/pemrek-verify/add");
        //   break;
        // case 3:
        //   this.$router.push("/merchant/add");
        //   break;
        case 1:
          this.$router.push("/pemrek-verify/add");
          break;
        case 2:
          this.$router.push("/pemrek-aladin-verify/add");
          break;
        case 3:
          this.$router.push("/adira/loan/add");
          break;
        case 4:
          this.$router.push("/pemrek-mandiri-verify/add");
          break;
        case 5:
          this.$router.push("/merchant-doku/add");
          break;
        case 6:
          this.$router.push("/pemrek-octo-verify/add");
          break;
      }
    },
  },
};
</script>

<style>
</style>
